import React, { useState } from 'react';
import ApiContext from "../api/api-context"
import { useQuery } from "react-query";
import { getBadgeData } from '../api/v1';
import PropTypes from 'prop-types';

const ApiWrapper = ({ children }) => {
  const { data: dodStemIssuer } = useQuery('badgeData', getBadgeData)
  const [user, setUser] = useState(null)

  return (
    <React.Fragment>
      <ApiContext.Provider value={{ dodStemIssuer, user, setUser }}>
        {children}
      </ApiContext.Provider>
    </React.Fragment>
  )
};

ApiWrapper.propTypes = {
  children: PropTypes.node,
};

ApiWrapper.defaultProps = {
  children: null,
};



export default ApiWrapper;