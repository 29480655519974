export const actions = {
  click: 'Click',
  submit: 'Submit',
  filter: 'Filter',
  play: 'Play',
  open: 'Open',
  close: 'Close',
  sort: 'Sort',
  tab: 'Tab',
};

export const categories = {
  link: 'Internal Link',
  outbound: 'Outbound Link',
  download: 'Download',
  accordion: 'Accordion',
  video: 'Video',
  search: 'Search',
  button: 'Button',
};

export const track = (category, action, label, value) => {
  if (process.env.GATSBY_DEV_SERVER === 'true') {
    /* eslint-disable no-console */
    return console.log('Analaytics event: ', {
      category,
      action,
      label,
      value,
    });
  }

  if (typeof window !== 'undefined' && window.gtag !== 'undefined') {
    return window.gtag('event', action, {
      category,
      label,
      value,
    });
  }

  return false;
};
