import { track, categories, actions } from "./event-tracking";
export const API_V1 = `${process.env.GATSBY_WEB_ROOT}/api/v1/`

export const BADGES = {
  'dod-stem-ambassador-inspiring-the-future': 'ambassador',
  'dod-stem-ambassador-inspiring-the-future-2023': 'ambassador',
}

export async function getUserFromEmail(email) {
  if (!email) { return false; }
  const res = await fetch(`${API_V1}awards/list/${email}`);

  if (res.status === 500) {
    return { error: 'User not found' }
  }

  const json = await res.json();
  if (json.length > 0) {
    return {
      email: email,
      awards: json,
    }
  }

  return false
}

export async function getUserFromAwardId(awardId) {
  const res = await fetch(`${API_V1}awards/find/${awardId}`);

  if (res.status === 500) {
    return { error: 'User not found' }
  }

  const json = await res.json();
  const email = json.recipient
  return await getUserFromEmail(email)
}

export const downloadAward = async(id, filetype, filename) => {
  track(categories.download, actions.click, `Download Badge - ${filetype.toUpperCase()}`)

  const res = await fetch(`${API_V1}awards/${id}.${filetype}`)
  let data = await res.blob();
  let blob = URL.createObjectURL(data)

  const anchor = document.createElement("a");
  anchor.href = blob;
  anchor.download = `${filename.replace(/ /g,"_")}.${filetype}`;
  anchor.click()
  URL.revokeObjectURL(anchor.href);
}

const issuers = [
  'dod-stem'
]

export const getBadgeData = async () => {
  const data = {
    issuers: [],
    badges: [],
  }

  for (let i = 0; i < issuers.length; i++) {
    try {
      let res = await fetch(`${API_V1}issuers/list/${issuers[i]}`)
      let json = await res.json();
      data.issuers[i] = json
    } catch(error) {
      console.log(Object.keys(error), error.message);
    }
  }

  return data
}

export function allBadges(issuers) {
  let badges = []

  for (let i = 0; i < issuers.length; i++) {
    
    for (let j = 0; j < issuers[i].badges.length; j++) {
      issuers[i].badges[j].issuer = issuers[i]
    }

    badges = badges.concat(issuers[i].badges)
  }

  return badges
}

export async function fetchBadge(code) {
  const res = await fetch(`${API_V1}badges/fetch/${code}`);
  const json = await res.json();
  json.status = res.status

  return json
}

export async function claimAward(data) {
  const res = await fetch(`${API_V1}awards/claim`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(data),
  })

  const json = await res.json();
  return json
}