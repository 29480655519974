exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-awards-ambassador-js": () => import("./../../../src/pages/awards/ambassador.js" /* webpackChunkName: "component---src-pages-awards-ambassador-js" */),
  "component---src-pages-awards-index-js": () => import("./../../../src/pages/awards/index.js" /* webpackChunkName: "component---src-pages-awards-index-js" */),
  "component---src-pages-awards-show-js": () => import("./../../../src/pages/awards/show.js" /* webpackChunkName: "component---src-pages-awards-show-js" */),
  "component---src-pages-badges-show-js": () => import("./../../../src/pages/badges/show.js" /* webpackChunkName: "component---src-pages-badges-show-js" */),
  "component---src-pages-claim-[code]-js": () => import("./../../../src/pages/claim/[code].js" /* webpackChunkName: "component---src-pages-claim-[code]-js" */),
  "component---src-pages-claim-index-js": () => import("./../../../src/pages/claim/index.js" /* webpackChunkName: "component---src-pages-claim-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-support-index-js": () => import("./../../../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */)
}

